(function ($) {

    $.fn.cslide = function () {

        this.each(function () {

            var self = $(this);

            var len = self.find(".cslide-slide").length;     // get number of slides
            var slidesContainerWidth = len * 100 + "%";                     // get width of the slide container
            var slideWidth = (100 / len) + "%";                             // get width of the slides

            // set slide container width
            self.find(".cslide-slides-container").css({
                width: slidesContainerWidth,
                visibility: "visible",
                marginLeft: 0  // Initialise to the first slide
            });
            
            // set slide width
            $(".cslide-slide").css({
                width: slideWidth
            });

            // add correct classes to first and last slide
            self.find(".cslide-slides-container .cslide-slide").last().addClass("cslide-last").removeClass("cslide-active");
            self.find(".cslide-slides-container .cslide-slide").first().addClass("cslide-first cslide-active");

            // initially disable the previous arrow cuz we start on the first slide
            self.find(".cslide-prev").addClass("cslide-disabled");
            self.find(".cslide-next").removeClass("cslide-disabled");

            // if first slide is last slide, hide the prev-next navigation
            if (!self.find(".cslide-slide.cslide-active.cslide-first").hasClass("cslide-last")) {
                self.find(".cslide-prev-next").css({
                    display: "block"
                });
            }

            // handle the next clicking functionality
            self.on("click", ".cslide-next", function () {
                var i = self.find(".cslide-slide.cslide-active").index();
                var n = i + 1;
                var slideLeft = "-" + n * 100 + "%";
                if (!self.find(".cslide-slide.cslide-active").hasClass("cslide-last")) {
                    self.find(".cslide-slide.cslide-active").removeClass("cslide-active").next(".cslide-slide").addClass("cslide-active");
                    self.find(".cslide-slides-container").animate({
                        marginLeft: slideLeft
                    }, 250);
                    if (self.find(".cslide-slide.cslide-active").hasClass("cslide-last")) {
                        self.find(".cslide-next").addClass("cslide-disabled");
                    }
                }
                if ((!self.find(".cslide-slide.cslide-active").hasClass("cslide-first")) && $(".cslide-prev").hasClass("cslide-disabled")) {
                    self.find(".cslide-prev").removeClass("cslide-disabled");
                }
            });

            // handle the prev clicking functionality
            self.on("click", ".cslide-prev", function () {
                var i = self.find(".cslide-slide.cslide-active").index();
                var n = i - 1;
                var slideRight = "-" + n * 100 + "%";
                if (!self.find(".cslide-slide.cslide-active").hasClass("cslide-first")) {
                    self.find(".cslide-slide.cslide-active").removeClass("cslide-active").prev(".cslide-slide").addClass("cslide-active");
                    self.find(".cslide-slides-container").animate({
                        marginLeft: slideRight
                    }, 250);
                    if (self.find(".cslide-slide.cslide-active").hasClass("cslide-first")) {
                        self.find(".cslide-prev").addClass("cslide-disabled");
                    }
                }
                if ((!self.find(".cslide-slide.cslide-active").hasClass("cslide-last")) && $(".cslide-next").hasClass("cslide-disabled")) {
                    self.find(".cslide-next").removeClass("cslide-disabled");
                }
            });
        });

        // return this for chainability
        return this;

    }

}(jQuery));